var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "task-view-badge-filter filter-list",
      staticStyle: { width: "100%" },
      style: { "--task-view-badge-filter-group-height": _vm.badgeGroupHeight },
      attrs: { id: _vm.id },
    },
    [
      _c("div", { staticClass: "filter-label" }, [
        _c("label", { staticClass: "mr-1 ml-2" }, [
          _vm._v(_vm._s(_vm.$t("staff.filters", [_vm.filterData.length]))),
        ]),
      ]),
      _c(
        "button",
        {
          staticClass: "add-filter-btn btn-action",
          attrs: { id: "BADGE_FILTER_ADD_" + _vm.id },
          on: { click: _vm.filterAdd },
        },
        [
          _c("font-awesome-icon", { attrs: { icon: ["far", "plus"] } }),
          _c("b-popover", {
            attrs: {
              target: "BADGE_FILTER_ADD_" + _vm.id,
              placement: "top",
              boundary: "viewport",
              triggers: "hover",
              content: _vm.$t("button.filter_add"),
            },
          }),
        ],
        1
      ),
      _c("BadgeGroup", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var item = ref.item
              var index = ref.index
              return [
                _c("Badge", {
                  key: "" + index,
                  attrs: {
                    text: item != null ? item.name : "",
                    variant: "primary",
                    pillable: item == null || !!item.pillable,
                  },
                  on: {
                    badgeRemove: function ($event) {
                      return _vm.badgeRemove(index)
                    },
                    badgeClick: function ($event) {
                      return _vm.badgeClick(index)
                    },
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.filterData,
          callback: function ($$v) {
            _vm.filterData = $$v
          },
          expression: "filterData",
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("filter_component.add_filter"),
            "ok-title": _vm.$t("button.ok"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.addFilter },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _vm.showFilterStaff
                    ? _c(
                        "b-form-checkbox",
                        {
                          staticStyle: { "margin-right": "auto" },
                          model: {
                            value: _vm.filterStaff,
                            callback: function ($$v) {
                              _vm.filterStaff = $$v
                            },
                            expression: "filterStaff",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t(_vm.filterCheckboxText)))]
                      )
                    : _vm._e(),
                  _vm.useLink
                    ? _c(
                        "b-form-checkbox",
                        {
                          staticStyle: { "margin-right": "auto" },
                          model: {
                            value: _vm.link,
                            callback: function ($$v) {
                              _vm.link = $$v
                            },
                            expression: "link",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("task.parent_tasks")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        disabled:
                          _vm.field === null ||
                          (!Array.isArray(_vm.values) && _vm.values === "") ||
                          (Array.isArray(_vm.values) &&
                            _vm.values.filter(function (v) {
                              return v.checked
                            }).length === 0),
                        size: "sm",
                        variant: "success",
                      },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.filterShow,
            callback: function ($$v) {
              _vm.filterShow = $$v
            },
            expression: "filterShow",
          },
        },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "field-type",
                label: _vm.$t("filter_component.filter_field"),
              },
            },
            [
              _c("b-form-select", {
                attrs: { options: _vm.fields },
                model: {
                  value: _vm.field,
                  callback: function ($$v) {
                    _vm.field = $$v
                  },
                  expression: "field",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            [
              !Array.isArray(_vm.values) && !_vm.isTextField
                ? _c("b-form-select", {
                    attrs: { options: _vm.operatorsAll, name: "operator" },
                    model: {
                      value: _vm.operator,
                      callback: function ($$v) {
                        _vm.operator = $$v
                      },
                      expression: "operator",
                    },
                  })
                : !Array.isArray(_vm.values) && _vm.isTextField
                ? _c("b-form-select", {
                    attrs: { options: _vm.operatorsTextAll, name: "operator" },
                    model: {
                      value: _vm.operator,
                      callback: function ($$v) {
                        _vm.operator = $$v
                      },
                      expression: "operator",
                    },
                  })
                : _c("b-form-radio-group", {
                    attrs: {
                      id: "radio-group-1",
                      options: _vm.operators,
                      name: "operator",
                    },
                    model: {
                      value: _vm.operator,
                      callback: function ($$v) {
                        _vm.operator = $$v
                      },
                      expression: "operator",
                    },
                  }),
            ],
            1
          ),
          Array.isArray(_vm.values)
            ? _c(
                "b-form-group",
                {
                  attrs: {
                    id: "field-value",
                    label: _vm.$t("filter_component.filter_value"),
                  },
                },
                [
                  _c(
                    "b-dropdown",
                    {
                      staticClass: "value-dropdown",
                      class:
                        _vm.filterValuesText !== ""
                          ? "notempty-value-dropdown"
                          : "",
                      attrs: { lazy: "", text: _vm.filterValuesText },
                    },
                    [
                      _vm.field &&
                      _vm.fieldValues[_vm.field] &&
                      _vm.fieldValues[_vm.field].length !== 0
                        ? _c("b-form-input", {
                            attrs: {
                              placeholder: _vm.$t("filter_component.search"),
                            },
                            model: {
                              value: _vm.listFilter,
                              callback: function ($$v) {
                                _vm.listFilter = $$v
                              },
                              expression: "listFilter",
                            },
                          })
                        : _vm._e(),
                      _c(
                        "b-dropdown-form",
                        [
                          _vm.field == "color"
                            ? _vm._l(_vm.values, function (value, index) {
                                return _c(
                                  "b-form-checkbox",
                                  {
                                    key: index,
                                    staticClass: "option-bg-color",
                                    style: {
                                      "--option-bg-color": value.text,
                                      "--option-color":
                                        value.text.length > 0 &&
                                        value.text != "(Empty)"
                                          ? _vm.invertColor(value.text, true)
                                          : "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        var i = arguments.length,
                                          argsArray = Array(i)
                                        while (i--) argsArray[i] = arguments[i]
                                        return _vm.checkboxChanged.apply(
                                          void 0,
                                          [index].concat(argsArray)
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.values[index].checked,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.values[index],
                                          "checked",
                                          $$v
                                        )
                                      },
                                      expression: "values[index].checked",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(value.text) + " ")]
                                )
                              })
                            : _vm._l(_vm.values, function (value, index) {
                                return _c(
                                  "b-form-checkbox",
                                  {
                                    key: index,
                                    on: {
                                      change: function ($event) {
                                        var i = arguments.length,
                                          argsArray = Array(i)
                                        while (i--) argsArray[i] = arguments[i]
                                        return _vm.checkboxChanged.apply(
                                          void 0,
                                          [index].concat(argsArray)
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.values[index].checked,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.values[index],
                                          "checked",
                                          $$v
                                        )
                                      },
                                      expression: "values[index].checked",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          value.text.replace(/\n/g, " / ")
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !Array.isArray(_vm.values)
            ? _c(
                "b-form-group",
                { attrs: { label: _vm.$t("filter_component.filter_value") } },
                [
                  _vm.isNumberField
                    ? _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            attrs: { type: "number" },
                            on: {
                              focusout: _vm.onNumberFocusOut,
                              keydown: _vm.onNumberKeyDown,
                            },
                            model: {
                              value: _vm.fieldValues[_vm.field],
                              callback: function ($$v) {
                                _vm.$set(_vm.fieldValues, _vm.field, $$v)
                              },
                              expression: "fieldValues[field]",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm.isTextField
                    ? _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.values,
                              callback: function ($$v) {
                                _vm.values = $$v
                              },
                              expression: "values",
                            },
                          }),
                        ],
                        1
                      )
                    : !_vm.isDateField
                    ? _c(
                        "b-input-group",
                        [
                          _c(
                            "b-input-group-prepend",
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: { id: "VAL_SUBTRACT" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.AddMinus(-1)
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "minus"] },
                                  }),
                                  _c("b-popover", {
                                    attrs: {
                                      target: "VAL_SUBTRACT",
                                      placement: "top",
                                      triggers: "hover",
                                      content: _vm.$t(
                                        "task.button.duration_subtract"
                                      ),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("b-form-input", {
                            on: {
                              focusout: _vm.onFocusOut,
                              keydown: _vm.onKeyDown,
                            },
                            model: {
                              value: _vm.fieldValues[_vm.field],
                              callback: function ($$v) {
                                _vm.$set(_vm.fieldValues, _vm.field, $$v)
                              },
                              expression: "fieldValues[field]",
                            },
                          }),
                          _c(
                            "b-input-group-append",
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: { id: "VAL_ADD" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.AddMinus(1)
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "plus"] },
                                  }),
                                  _c("b-popover", {
                                    attrs: {
                                      target: "VAL_ADD",
                                      placement: "top",
                                      triggers: "hover",
                                      content: _vm.$t(
                                        "task.button.duration_add"
                                      ),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "b-input-group",
                        [
                          _c("b-form-datepicker", {
                            staticClass: "mb-2",
                            attrs: {
                              "today-button": "",
                              "reset-button": "",
                              "close-button": "",
                              "hide-header": "",
                              boundary: "viewport",
                              "popper-opts": { positionFixed: true },
                              "label-today-button": _vm.$t("date.today"),
                              "label-reset-button": _vm.$t("date.reset"),
                              "label-close-button": _vm.$t("date.close"),
                              "today-button-variant": "primary",
                              "reset-button-variant": "danger",
                              "close-button-variant": "secondary",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "button-content",
                                  fn: function (ref) {
                                    return [
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          icon: ["far", "calendar-days"],
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2621928167
                            ),
                            model: {
                              value: _vm.values,
                              callback: function ($$v) {
                                _vm.values = $$v
                              },
                              expression: "values",
                            },
                          }),
                        ],
                        1
                      ),
                ],
                1
              )
            : _vm._e(),
          !Array.isArray(_vm.values) &&
          _vm.operator === "between" &&
          !_vm.isTextField
            ? _c(
                "b-form-group",
                { attrs: { label: _vm.$t("filter_component.filter_value") } },
                [
                  _vm.isNumberField
                    ? _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            attrs: { type: "number" },
                            on: {
                              focusout: _vm.onNumberFocusOut,
                              keydown: _vm.onNumberKeyDown,
                            },
                            model: {
                              value: _vm.value2,
                              callback: function ($$v) {
                                _vm.value2 = $$v
                              },
                              expression: "value2",
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "b-input-group",
                        [
                          _c("b-form-datepicker", {
                            staticClass: "mb-2",
                            attrs: {
                              "today-button": "",
                              "reset-button": "",
                              "close-button": "",
                              "hide-header": "",
                              boundary: "viewport",
                              "popper-opts": { positionFixed: true },
                              "label-today-button": _vm.$t("date.today"),
                              "label-reset-button": _vm.$t("date.reset"),
                              "label-close-button": _vm.$t("date.close"),
                              "today-button-variant": "primary",
                              "reset-button-variant": "danger",
                              "close-button-variant": "secondary",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "button-content",
                                  fn: function (ref) {
                                    return [
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          icon: ["far", "calendar-days"],
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2621928167
                            ),
                            model: {
                              value: _vm.value2,
                              callback: function ($$v) {
                                _vm.value2 = $$v
                              },
                              expression: "value2",
                            },
                          }),
                        ],
                        1
                      ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }